(function () {
    'use strict';

    // @ngInject
    function GoogleRedirectControllerCtor($window, $scope, $injector, $location, $log, $q, InitialAppLoadParamsService, AnalyticsService, AppStates, UsersManager, PopupMessageService, AbTestService, Enums, UiPersistenceService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'GoogleRedirectController';
        AnalyticsService.trackPageView(this, 'GoogleRedirectView');
        this.$log = $log;
        this.$q = $q;
        this.$window = $window;

        this.AppStates = AppStates;
        this.UsersManager = UsersManager;
        this.user = this.UsersManager.getCurrUser();
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;

        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this.UiPersistenceService = UiPersistenceService;

        var queryStringParams = InitialAppLoadParamsService.getAllLoadParams();
        this.$log.info("queryStringParams debug: " + JSON.stringify(queryStringParams));
        InitialAppLoadParamsService.cleanParamsFromView(['state', 'code', 'error']);

        if (!queryStringParams || !queryStringParams.state) {
            this.$log.error('could not identify query state. queryStringParams keys: ' + Object.keys(queryStringParams));
            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'We encountered a problem while connecting with your Google account. Please review your Google account settings and retry.', function onPopupConfirm() {
                this.goToState(AppStates.root_core_navigation_settings_company_integrations);
            }.bind(this));
            return;
        }
        // saved the auth on the server model can now continue and return to the page we stated from.
        var stateAsObject = JSON.parse(queryStringParams.state);

        if (stateAsObject && queryStringParams.code) {
            this.UsersManager.setGoogleAuthCode(queryStringParams.code, stateAsObject.integration_type, stateAsObject.validation_code)
                .then(function success() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.save_gmail_integration_auth_code, {source: stateAsObject.origin_state});

                    switch (stateAsObject.origin_state) {
                        case 'calendar':
                            this.goToState(this.AppStates.root_core_navigation_calendar, {googleIntegrationCreated: true});
                            this.$log.log('switching to the calendar view');
                            break;
                        case 'gmail':
                            return this.setActiveEmailProvider().then(function success() {
                                this.goToState(this.AppStates.root_core_navigation_settings_company_integrations, {googleIntegrationCreated: true, integrationType: stateAsObject.integration_type});
                                this.$log.log('switching to the account view on the setting');
                            }.bind(this));
                        case 'contacts':
                            this.goToState(this.AppStates.root_core_navigation_contacts, {googleIntegrationCreated: true});
                            this.$log.log('switching to the contacts view');
                            break;
                        case 'referrals':
                            this.goToState(this.AppStates.root_core_navigation_invite_referrals, {googleIntegrationCreated: true});
                            this.AnalyticsService.trackRedirect(this, this.AnalyticsService.analytics_events.referral_dashboard);
                            this.$log.log('switching to the referral dashboard view');
                            break;
                        case 'popup':
                            this.$log.log('google auth was done in a popup, no redirect');
                            this.$window.opener.onGoogleRedirectComplete(true);
                            this.$window.close();
                            break;
                        case 'integrations':
                            if (stateAsObject.integration_type === 'calendar') {
                                this.goToState(this.AppStates.root_core_navigation_calendar, {googleIntegrationCreated: true});
                                this.$log.log('switching to the calendar view');
                                break;
                            }
                            else {
                                this.goToState(this.AppStates.root_core_navigation_settings_company_integrations, {googleIntegrationCreated: true, integrationType: stateAsObject.integration_type});
                                this.$log.log('switching to the integrations view');
                                break;
                            }
                            break;
                        case 'home':
                            if(stateAsObject.integration_type === 'gmail'){
                                return this.setActiveEmailProvider().then(function success() {
                                    var homePersistency = this.UiPersistenceService.getUiPersistence(this.UiPersistenceService.keys.oneHomeZeroStates, {});
                                    homePersistency.inbox = false;
                                    this.UiPersistenceService.setUiPersistence(this.UiPersistenceService.keys.oneHomeZeroStates, homePersistency);

                                    this.goToState(this.AppStates.root_core_navigation_home, {googleIntegrationCreated: true});
                                    this.$log.log('switching to home view');
                                }.bind(this));
                            }else if(stateAsObject.integration_type === 'calendar'){
                                this.goToState(this.AppStates.root_core_navigation_calendar, {googleIntegrationCreated: true});
                                this.$log.log('switching to the calendar view');
                            }
                            break;
                        default:
                            this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._TRY_AGAIN_');
                            this.$log.error('could not identify the origin_state code. this should not happen');
                            break;
                    }

                }.bind(this))
                .catch(function fail(resp) {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.save_gmail_integration_auth_code, resp);
                    if (resp && resp.data && resp.data.error_type && resp.data.error_message && resp.data.error_type === "HBGoogleIntegrationNotGmailError") {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message, function onPopupConfirm() {
                            this.goBackToPreviousStateOnError(stateAsObject);
                        }.bind(this));
                    } else if (resp && resp.data && resp.data.error_type && resp.data.error_message && resp.data.error_type === "HBGoogleIntegrationMismatchedEmailError") {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message, function onPopupConfirm() {
                            this.goBackToPreviousStateOnError(stateAsObject);
                        }.bind(this));
                    } else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._TRY_AGAIN_', function onPopupConfirm() {
                            this.goBackToPreviousStateOnError(stateAsObject);
                        }.bind(this));
                    }

                    this.$log.error('failed to save the auth-code on the google integration model');
                }.bind(this));

        } else if (queryStringParams.error) {
            this.$log.error('Error in google authentication process: ' + $location.url());
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.gmail_integration_google_error);
            this.$log.error('user rejected the permissions on google authentication page');
            if (stateAsObject) {
                // if we are here this means that the user has pressed deny on the google authentication page.
                // simply go back to where the user started from.
                this.goBackToPreviousStateOnError(stateAsObject);
            } else {
                //we dont have the origin state for some reason... to the pipeline we go...
                this.$log.error('could not identify the origin_state code. this should not happen');
                this.goToState(AppStates.root_core_navigation_pipeline);
            }
        }
    }

    Controllers.GoogleRedirectController = Class(Controllers.BaseController, {
        constructor: GoogleRedirectControllerCtor,

        setActiveEmailProvider: function setActiveEmailProvider() {
            return this.UsersManager.setActiveEmailProvider(this.user, 'gmail');
        },

        goBackToPreviousStateOnError: function goBackToPreviousStateOnError(stateObject) {
            switch (stateObject.origin_state) {
                case 'calendar':
                    this.goToState(this.AppStates.root_core_navigation_calendar);
                    break;
                case 'gmail':
                    this.goToState(this.AppStates.root_core_navigation_settings_company_integrations);
                    break;
                case 'contacts':
                    this.goToState(this.AppStates.root_core_navigation_contacts);
                    break;
                case 'popup':
                    this.$log.log('google auth was done in a popup, no redirect');
                    this.$window.opener.onGoogleRedirectComplete(false);
                    this.$window.close();
                    break;
                case 'integrations':
                    this.goToState(this.AppStates.root_core_navigation_settings_company_integrations);
                    this.$log.log('switching to the integrations view on error');
                    break;
                case 'home':
                    this.goToState(this.AppStates.root_core_navigation_home, {googleIntegrationCreated: true});
                    this.$log.log('switching to home view on error');
                    break;
                default:
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._TRY_AGAIN_', function onPopupConfirm() {
                        this.goToState(this.AppStates.root_core_navigation_pipeline);
                    }.bind(this));
                    this.$log.error('could not identify the origin_state code. this should not happen');
                    break;
            }
        }
    });
}());

Function.ubind = function (self) {
    var f = this;
    return function () {
        f.apply(self, Array.prototype.slice.call(arguments, 0));
    };
};
